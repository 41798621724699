import Vue from 'vue'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

const signalrConnection = new HubConnectionBuilder()
  .withUrl(`${window.config.websocketBaseUrl}?access_token=${localStorage.getItem('token')}`)
  .withAutomaticReconnect()
  .configureLogging(LogLevel.Information)
  .build()

Vue.prototype.$socket = signalrConnection

export default signalrConnection
