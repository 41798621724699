<template>
  <div>
    <div class="personal-info">
      <h6 class="section-label mb-1">
        Informations personnelles
      </h6>
      <ul class="list-unstyled m-0">
        <li v-if="young.phoneNumber" class="d-flex align-items-center mb-2">
          <feather-icon icon="SmartphoneIcon" size="16" class="flex-shrink-0 mr-1" />
          <span>{{ formatPhoneNumber(young.phoneNumber) }}</span>
          <b-button v-if="!young.isBlocked" variant="danger" class="block-btn" @click="openModal()">
            Bloquer
          </b-button>
          <b-button v-else-if="canUnblock" variant="success" class="block-btn" @click="openModal()">
            Débloquer
          </b-button>
        </li>
        <li v-if="young.ageRange" class="d-flex align-items-center mb-2">
          <feather-icon icon="CalendarIcon" size="16" class="flex-shrink-0 mr-1" />
          <span>{{ getAgeRangeLabel(young.ageRange) }}</span>
        </li>
        <li v-if="young.email" class="d-flex align-items-center mb-2">
          <feather-icon icon="MailIcon" size="16" class="flex-shrink-0 mr-1" />
          <span>{{ young.email }}</span>
        </li>
        <li v-if="young.gender" class="d-flex align-items-center mb-2">
          <feather-icon icon="UserIcon" size="16" class="flex-shrink-0 mr-1" />
          <span>{{ gender }}</span>
        </li>
        <li v-if="young.address || young.zipCode || young.city" class="d-flex align-items-center mb-2">
          <feather-icon icon="MapPinIcon" size="16" class="flex-shrink-0 mr-1" />
          <address class="m-0">
            {{ young.address }}
            <br>
            {{ young.zipCode }} {{ young.city }}
          </address>
        </li>
        <li v-if="young.region || young.department" class="d-flex align-items-center mb-2">
          <feather-icon icon="MapIcon" size="16" class="flex-shrink-0 mr-1" />
          <span>{{ `${young.department} ${departementName} - ${regionName}` }}</span>
        </li>
      </ul>
    </div>
    <div v-if="young.demandType || young.demandReason">
      <h6 class="section-label mb-1">
        Demande
      </h6>
      <ul class="list-unstyled m-0">
        <li v-if="young.demandType" class="d-flex align-items-center mb-2" title="Type de demande">
          <feather-icon icon="HelpCircleIcon" size="16" class="flex-shrink-0 mr-1" />
          <span>{{ demandType }}</span>
        </li>
        <li v-if="young.demandReason" class="d-flex align-items-center mb-2" title="Raison de la demande">
          <feather-icon icon="ClipboardIcon" size="16" class="flex-shrink-0 mr-1" />
          <span>{{ demandReason }}</span>
        </li>
      </ul>
    </div>
    <div>
      <h6 class="section-label mb-1">
        Situation
      </h6>
      <ul class="list-unstyled m-0">
        <li class="d-flex align-items-center mb-2" title="Situation d'hébergement">
          <feather-icon icon="HomeIcon" size="16" class="flex-shrink-0 mr-1" />
          <span>{{ accomodationSituation }}</span>
        </li>
        <li class="d-flex align-items-center mb-2" title="Comment la personne nous a connu">
          <feather-icon icon="InfoIcon" size="16" class="flex-shrink-0 mr-1" />
          <span>{{ howThePersonGotToKnowUs }}</span>
        </li>
      </ul>
    </div>
    <div v-if="young.observation" class="comment">
      <h6 class="section-label mb-1 mt-3">
        Observations
      </h6>
      <p>{{ young.observation }}</p>
    </div>
    <b-modal ref="modal" hide-header hide-footer :title="young.isBlocked ? 'Débloquer le numéro' : 'Bloquer le numéro'">
      <h2 class="text-primary text-center mb-2">
        {{ young.isBlocked ? 'Débloquer' : 'Bloquer' }} le numéro
      </h2>
      <p class="text-center">Êtes-vous sûr de vouloir {{ young.isBlocked ? 'débloquer' : 'bloquer' }} cet utilisateur ?
      </p>
      <p class="text-center mb-3">Dès lors, la personne {{ young.isBlocked ? 'pourra de nouveau contacter'
        : 'n\'aura plus la possibilité de joindre' }} la ligne Le Refuge.</p>
      <div class="d-flex justify-content-end">
        <b-button variant="danger" class="mr-2" @click="closeModal()">
          Annuler
        </b-button>
        <b-button variant="primary" @click="updateUserBlockingState()">
          Valider
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { formatDate, formatPhoneNumber } from '@core/utils/filter'
import { mapGetters } from 'vuex'
import { BButton, BModal } from 'bootstrap-vue'
import departements from '@/assets/departments.json'
import store from '@/store'

export default {
  components: {
    BButton,
    BModal,
  },
  props: {
    young: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    return {
      formatDate,
      formatPhoneNumber,
      perfectScrollbarSettings,
    }
  },
  data() {
    return {
      departements,
      notSpecified: 'Non renseigné',
    }
  },
  computed: {
    ...mapGetters({
      taxonomies: 'initialData/getTaxonomies',
      role: 'users/getUserRole',
    }),
    departementName() {
      return this.departements.find(d => String(d.num_dep) === String(this.young.department))?.dep_name
    },
    regionName() {
      return this.departements.find(d => String(d.num_dep) === String(this.young.department))?.region_name
    },
    accomodationSituation() {
      if (!this.young.situationOfAccommodation) {
        return this.notSpecified
      }

      return this.getTaxonomyLabel('situationOfAccommodation', this.young.situationOfAccommodation)
    },
    howThePersonGotToKnowUs() {
      if (!this.young.howThePersonGotToKnowUs) {
        return this.notSpecified
      }

      return this.getTaxonomyLabel('howThePersonGotToKnowUs', this.young.howThePersonGotToKnowUs)
    },
    gender() {
      if (!this.young.gender) {
        return this.notSpecified
      }

      return this.getTaxonomyLabel('gender', this.young.gender)
    },
    demandType() {
      if (!this.young.demandType) {
        return ''
      }

      return this.getTaxonomyLabel('demandType', this.young.demandType)
    },
    demandReason() {
      if (!this.young.demandReason) {
        return ''
      }

      return this.getTaxonomyLabel('demandReason', this.young.demandReason)
    },
    canUnblock() {
      return this.young.isBlocked && this.role.includes('Coordinateur')
    },
  },
  methods: {
    getTaxonomyLabel(taxonomy, propertyValue) {
      return this.taxonomies[taxonomy].find(entry => entry.id === propertyValue)?.label || ''
    },
    getAgeRangeLabel(ageRange) {
      const ageRanges = [
        'Ne souhaite pas répondre',
        '-14 ans',
        '14-17 ans',
        '18-25ans',
        '26-30 ans',
        '31-50 ans',
        '50 et +',
      ]
      return ageRanges[ageRange] || 'Non renseigné'
    },
    updateUserBlockingState() {
      const {
        address, department, region, zipCode, archivedForFiveYears, ageRange, city, firstName, id, lastName, email, gender, demandType, howThePersonGotToKnowUs, demandReason, situationOfAccommodation, isBlocked,
      } = this.young
      const youngUpdate = {
        address, department, region, zipCode, archivedForFiveYears, ageRange, city, firstName, id, lastName, email, gender, demandType, howThePersonGotToKnowUs, demandReason, situationOfAccommodation, isBlocked: !isBlocked,
      }

      store.dispatch('youngs/updateYoungDetails', youngUpdate)
      this.closeModal()
    },
    closeModal() {
      this.$refs.modal.hide()
    },
    openModal() {
      this.$refs.modal.show()
    },
  },
}
</script>

<style>
.block-btn {
  display: block;
  margin-left: auto;
}
</style>
